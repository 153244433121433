import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { config, CheckAuth, getApiToken } from './../../constans';

export const PaymentSlice = createSlice({
    name: 'PaymentSlice',
    initialState: {
        stripeResponse: [],
        stripeError: [],
        paypalResponse: [],
        paypalError: []
    },
    reducers: {
        setStripeResponse: (state, { payload }) => {
            state.stripeResponse = payload.data;
            state.stripeError = [];
        },
        setStripeError: (state, { payload }) => {
            state.stripeError = payload.data;
        },
        setPayPalResponse: (state, { payload }) => {
            state.paypalResponse = payload.data;
            state.paypalError = [];
        },
        setPayPalError: (state, { payload }) => {
            state.paypalError = payload.data;
        },
    },
})
  
// Action creators are generated for each case reducer function
export const {
    setStripeResponse,
    setStripeError,
    setPayPalResponse,
    setPayPalError
} = PaymentSlice.actions
  
// Selectors
export const storeStripeResponse = state => state.PaymentSlice.stripeResponse;
export const storeStripeError = state => state.PaymentSlice.stripeError;
  
export default PaymentSlice.reducer
  
  // API Function
export function makeStripePayment(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_MAKE_ORDER_PAYMENT, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setStripeResponse(data))
                } else {
                    dispatch(setStripeError(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setStripeError(error.response))
            });
    }
}

export function makePayPalPayment(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_MAKE_PAYPAL_PAYMENT, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setPayPalResponse(data))
                } else {
                    dispatch(setPayPalError(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setPayPalError(error.response))
            });
    }
}