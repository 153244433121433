import { t } from 'i18next';
import React from 'react'
import { useState } from 'react';
import DownArrowIcon from './DownArrowIcon';
import UpArrowIcon from './UpArrowIcon';

export default function SwappedOrderDetails(props) {
    const [accordionOpen, setAccordionOpen] = useState(true);
    const getFullAddress = (address) => {
        let add = address.delivery_street;
        add += (address.delivery_address_2) ? ', '+address.delivery_address_2 : '';
        add += (address.delivery_city) ? ', '+address.delivery_city : '';
        add += (address.delivery_zip_code) ? ', ' + address.delivery_zip_code : '';
        return add;
    }
    return (
        <>
            <div className="accordion border-0 mt-2" id={`accordionExample-${props.swap_order.order_id}`}>
                <div className="accordion-item border-0">
                    <h2 className="accordion-header theme-light-yellow" id={`headingOne-${props.swap_order.order_id}`}>
                        <center><button onClick={() => {setAccordionOpen(accordionOpen ? false : true)}} className="btn w-75 rounded-lg theme-btn" style={{ backgroundColor: '#373F43', color: '#FFFFFF' }} type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne-${props.swap_order.order_id}`} aria-expanded="true" aria-controls={`collapseOne-${props.swap_order.order_id}`}>{t('See swap order')}
                            {accordionOpen ? <DownArrowIcon /> : <UpArrowIcon />}
                            </button></center>
                    </h2>
                    <div id={`collapseOne-${props.swap_order.order_id}`} className="accordion-collapse collapse show theme-light-yellow" aria-labelledby={`headingOne-${props.swap_order.order_id}`} data-bs-parent={`#accordionExample-${props.swap_order.order_id}`}>
                    <div className="accordion-body bg-transparent">
                    <h5 className="alert-heading">{ t('Swapped Order') } - { props.swap_order.order_id }</h5>
                    <p>{props.swap_order.container_size + 'm3'} {props.swap_order.container_name} ({props.swap_order.garbage_name}) <span className='float-end'>{props.swap_order.total_amount + ' €'}</span></p>
                    <p><strong>{t('Pickup date')}:</strong> {props.swap_order.pickup_date} {props.swap_order.pickup_time}</p>
                    <p><strong>{t('Delivery date')}:</strong> {props.swap_order.delivery_date} {props.swap_order.delivery_time }</p>
                    <hr/>
                    <p className="mb-0"><strong>{ t('Address') }: </strong> {getFullAddress(props.swap_order)}</p>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
