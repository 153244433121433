import React from 'react'
import FrontendLayout from '../Layout/FrontendLayout'
import SkipHireSteps from '../Components/SkipHireSteps'
import TypesOfWaste from '../Components/TypesOfWaste'
import HeaderBanner from './../Layout/HeaderBanner'

export default function Home() {
    
    return (
        <FrontendLayout>
            <HeaderBanner />
            <SkipHireSteps />
            <TypesOfWaste />
        </FrontendLayout>
  )
}
