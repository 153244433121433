import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios';
import { config, CheckAuth, getApiToken } from './../../constans';

export const CouponCodeSlice = createSlice({
    name: 'CouponCodeSlice',
    initialState: {
          CouponCodeResponse: {},
          CouponCodeErrors: [],
          CouponCodeErrorMessage: ''
    },
    reducers: {
        setCouponCodeResponse: (state, { payload }) => {
            state.CouponCodeResponse = payload.data;
            localStorage.setItem('coupon_data', JSON.stringify(payload.data));
            state.CouponCodeErrors = [];
            state.CouponCodeErrorMessage = '';
        },
        setCouponCodeErrors: (state, { payload }) => {
            state.CouponCodeErrors = payload.errors;
            state.CouponCodeErrorMessage = payload.message;
        },
    },
})
  
// Action creators are generated for each case reducer function
export const {
    setCouponCodeResponse,
    setCouponCodeErrors
} = CouponCodeSlice.actions
  
// Selectors
export const storeCouponCodeResponse = state => state.CouponCodeSlice.CouponCodeResponse;
export const storeCouponCodeErrors = state => state.CouponCodeSlice.CouponCodeErrors;
export const storeCouponCodeErrorMessage = state => state.CouponCodeSlice.CouponCodeErrorMessage;

  
export default CouponCodeSlice.reducer
  
  // API Function
export function sendCouponCode(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                //'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_APPLY_COUPON, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setCouponCodeResponse(data))
                } else {
                    dispatch(setCouponCodeErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setCouponCodeErrors(error.response))
            });
    }
}

export function removeCouponCode(params = {}) {
    return async dispatch => {
        const options = {
            headers: {
                'Accept': 'application/json',
                //'api-token': getApiToken(),
            }
        };
        return await axios.post(config.API_BASE_URL+config.API_REMOVE_COUPON, params, options)
            .then(({ data }) => {
                if (data.status === 200) {
                    dispatch(setCouponCodeResponse(data))
                } else {
                    dispatch(setCouponCodeErrors(data))
                }
                return data;
            })
            .catch(function (error) {
                CheckAuth(error.response.status);
                dispatch(setCouponCodeErrors(error.response))
            });
    }
}